<template>
  <drop-file
    :readonly="readonly"
    @addfiles="addFiles"
  >
    <template v-slot="{fileDropListener}">
      <download-file>
        <template v-slot="{downloadFile}">
          <icon-tooltip
            v-if="readonly && dense && selectedFiles.length === 1"
            dense
            bottom
            color="primary"
            icon-name="i.Paperclip"
            :tooltip-text="$t('t.Attachments')"
            @click.stop="downloadFile(selectedFiles[0], queryConfig)"
          />
          <v-menu
            v-else
            nudge-bottom="2"
            offset-y
            right
            :close-on-content-click="false"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on }">
              <v-badge
                overlap
                :value="selectedFiles.length"
                bordered
              >
                <v-btn
                  fab
                  :small="!dense"
                  :x-small="dense"
                  color="primary"
                  v-on="{...on, ...fileDropListener}"
                  :disabled="readonly && !selectedFiles.length"
                >
                  <icon-tooltip
                    icon-name="i.Paperclip"
                    :tooltip-text="$t('t.Attachments')"
                  /> </v-btn>
                <template v-slot:badge>{{selectedFiles.length}}</template>
              </v-badge>
            </template>
            <v-card class="pa-2">
              <v-list
                dense
                class="py-0"
              >
                <bounded-list
                  displayed-lines="10"
                  filter-bar="false"
                >
                  <v-list-item
                    dense
                    :key="index"
                    v-for="(file,index) in selectedFiles"
                    v-on="fileDropListener"
                  >
                    <v-list-item-content>
                      {{file.name.split(/\\|\//).pop()}}
                    </v-list-item-content>
                    <v-list-item-action class="d-flex flex-row my-0">
                      <v-btn icon>
                        <v-icon @click="downloadFile(file, queryConfig)">{{$icon('i.Download')}}</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        v-if="!readonly && file.id"
                      >
                        <v-icon @click="removeFile(index)">{{$icon('i.Delete')}}</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </bounded-list>
                <v-list-item
                  class="mt-2"
                  v-if="!readonly"
                  v-on="fileDropListener"
                >
                  <v-file-input
                    dense
                    :multiple="multiple"
                    :placeholder="`${$t('t.AddFile')}...`"
                    @change="addFiles"
                    v-model="files"
                  >
                    <template v-slot:selection="" />
                  </v-file-input>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </template>
      </download-file>
    </template>
  </drop-file>
</template>
<script>
export default {
  components: {
    BoundedList: () => import('@/components/bounded-list'),
    DropFile: () => import('@/components/drop-file'),
    DownloadFile: () => import('@/components/download-file'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  data () {
    return {
      files: [],
      isPropReceived: false,
      initialFiles: this.lodash.cloneDeep(Array.isArray(this.selection) ? this.selection : (this.selection ? [{ name: this.selection }] : [])),
      multiple: Array.isArray(this.selection)
    }
  },
  computed: {
    selectedFiles: {
      get () {
        return this.lodash.cloneDeep(Array.isArray(this.selection) ? this.selection : (this.selection ? [{ name: this.selection }] : []))
      },
      set (selectedFiles) {
        this.$emit('update:selection', this.multiple ? selectedFiles : (this.selectedFiles ? this.selectedFiles[0]?.name : null))
      }
    }
  },
  methods: {
    async addFiles (files) {
      files = files.filter(f => !this.selectedFiles.map(sf => sf.name).includes(f.name))
      if (files.length === 0) { return }
      const uploadedFiles = await Promise.all(files.map(async file => {
        const formData = new FormData()
        formData.append('file', file)
        const id = (await this.$http().post('/file', formData)).data
        return {
          id,
          name: file.name
        }
      }))
      this.selectedFiles = [...this.selectedFiles, ...uploadedFiles]
      this.files = []
    },
    removeFile (fileIndex) {
      const clonedSelectedFiles = this.selectedFiles.map(p => p)
      const deletedFile = clonedSelectedFiles.splice(fileIndex, 1)[0]
      this.selectedFiles = clonedSelectedFiles
      if (!this.initialFiles.map(f => f.id).includes(deletedFile.id) && this.hardDelete) {
        this.$http().delete(`/file/${deletedFile.id}`)
      }
    },
    async applyDeletion () {
      if (this.hardDelete) {
        const filesToDelete = this.initialFiles.filter(f => !this.selectedFiles.find(sf => f.id ? sf.id === f.id : sf.name === f.name))
        await Promise.all(filesToDelete.map(file => this.$http().delete(`/file/${file.id}`)))
        this.initialFiles = this.selectedFiles
      }
    }
  },
  watch: {
    selection () {
      if (!this.isPropReceived) {
        this.isPropReceived = true
        this.initialFiles = this.lodash.cloneDeep(Array.isArray(this.selection) ? this.selection : (this.selection ? [{ name: this.selection }] : []))
        this.multiple = Array.isArray(this.selection)
      }
    }
  },
  props: {
    queryConfig: Object,
    readonly: Boolean,
    selection: {
      type: [Array, String]
    },
    hardDelete: Boolean,
    dense: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.v-file-input
  min-width 200px
</style>
